<template>
    <div class="height-to-window">
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <h4 class="mt-2">{{$t('auth.reset_password')}}</h4>
            <!-- form -->
            <b-form ref="form">
                <!-- password -->
                <b-form-group
                    id="group-password"
                    :label="$t('auth.password')"
                    label-for="password"
                >
                    <b-form-input
                        id="password"
                        v-model="record.password"
                        :placeholder="$tc('auth.password_warning', 1)"
                        :state="validPassword"
                        :type="record.showPassword === true ? 'text' : 'password'"
                        required
                    ></b-form-input>
                    <b-form-invalid-feedback :state="validPassword">
                        <div v-html="$tc('auth.password_warning', 1)"/>
                    </b-form-invalid-feedback>
                </b-form-group>
                <!-- show password -->
                <b-form-checkbox
                    id="show-password"
                    v-model="record.showPassword"
                    name="active"
                >
                    {{$t('auth.show_password')}}
                </b-form-checkbox>
                <!-- button save -->
                <b-form-group
                    id="group-button-save"
                    label-for="button-save"
                >
                    <b-button
                        id="button-save"
                        variant="primary"
                        @click="save()"
                    >
                        {{$t('form.confirm')}}
                    </b-button>
                </b-form-group>

            </b-form>
        </b-overlay>
        <!-- alert error -->
        <b-alert
            :show="errors.message != null"
            class="mb-0"
            dismissible
            variant="danger"
            @dismissed="resetErrors"
        >
            <div v-html="formatError"></div>
        </b-alert>
    </div>
</template>

<script>
export default {
    name: "ResetPassword",
    components: {},
    props: {},
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            uri: {
                main: `api/v1/auth`,
            },
            record: {
                password: null,
                showPassword: false,
            },
            form: {
                valid: true,
            },
            errors: {
                message: null,
            },
        }
    },
    mounted() {
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        resetErrors() {
            this.errors.message = null;
        },
        // <-- form -->
        validate() {
            this.form.valid = this.$refs.form.checkValidity();
        },
        save() {
            this.errors.message = null;
            this.validate();
            if (!this.form.valid) {
                return;
            }

            let token = this.$route.query.key;
            // <-- axios -->
            this.showOverlay(true);

            this.axios.post(this.uri.main + '/reset_password',
                {
                    'locale': this.$i18n.locale,
                    'token': token,
                    'password': this.record.password
                })
                .then((response) => {
                    this.showOverlay(false);

                    this.msgBoxPasswordChanged();
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                })
                .finally(function () {
                });
        },
        // <-- message boxes -->
        msgBoxPasswordChanged() {
            // <-- HTML string -->
            const h = this.$createElement;
            const titleVNode = h('div',
                {
                    domProps: {innerHTML: this.$t('auth.reset_password')}
                });
            const messageVNode = h('div',
                {
                    domProps: {innerHTML: this.$t('auth.reset_password_message')}
                });
            // <-- modal message box -->
            this.$bvModal.msgBoxOk([messageVNode],
                {
                    title: [titleVNode],
                    size: 'md',
                    headerBgVariant: 'info',
                    footerBgVariant: 'info',
                    buttonSize: 'sm',
                    okVariant: 'primary',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true,
                })
                .then(value => {
                    this.$router.push('/');
                })
                .catch(error => {
                });
        }
    },
    computed: {
        // <-- errors -->
        formatError() {
            let message = '';

            let error = this.errors.message;
            if (error === null) {
                return null;
            }
            // <-- error.message -->
            if (error.message !== undefined) {
                message = error.message;
            }
            // <-- error.errors -->
            if (error.errors !== undefined && typeof error.errors === 'object') {
                for (let key in error.errors) {
                    message += '<br><b>' + key + ':</b> ' + error.errors[key][0];
                }
            }
            // <--  -->
            return message;
        },
        // <-- validate -->
        validPassword() {
            if (this.form.valid) {
                return null;
            }
            let value = this.record.password;
            return value == null || value.length < 6 ? false : null;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
